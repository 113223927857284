import { horizFetch } from '../core/api'

const citiesUtils = {
  mapCities: (array) => {
    return array.map((city) => ({
      value: city.id,
      label: city.value,
      locality: city.locality,
      postalCode: city.postalCode,
      hasMultiplePostalCodes: city.hasMultiplePostalCodes || null,
    }))
  },
}

export const useCitiesApi = () => ({
  getCities: async (query) => {
    const results = await horizFetch('/simulation/search-city', { query: { query } })

    return citiesUtils.mapCities(results)
  },
})
